<template>
<b-card title="SMPP">
    <div>
    <p> SMPP is a mature and standardized protocol for sending and receiving SMS messages that is well understood across the world. To facilitate integration of our API with existing SMPP connections, you can request for an SMPP account (at no additional cost).

You will be able to quickly integrate with our API and enjoy all the benefits that come with it (dashboard, premium services etc).

To request for an account, please get in touch with us either on chat, or by sending an email to info@africastalking.com.</p>
        <b-form ref="form">
            <!-- Row Loop -->
            <b-row ref="row">

                <!-- Item Name -->
                <b-col md="4">
                    <b-form-group label="Start Date" label-for="item-name">
                        <b-form-datepicker id="startdatepicker-buttons" today-button reset-button close-button v-model="startDate" locale="en" />
                    </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4">
                    <b-form-group label="End Date" label-for="item-name">
                        <b-form-datepicker id="enddatepicker-buttons" today-button reset-button close-button v-model="endDate" locale="en" />
                    </b-form-group>
                </b-col>

                <!-- Profession -->

                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="showTables()">
                        <feather-icon icon="SearchIcon" class="mr-25" />
                        <span>Search</span>
                    </b-button>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
            </b-row>
            <b-row ref="inboxList" v-show="showTable">
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                    <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                                <template v-slot:first>
                                    <option value="">
                                        -- none --
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-1">
                    <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-table :per-page="perPage" :items="items" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" striped responsive>
                    <template #cell(show_details)="row">
                        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                            <span class="vs-checkbox">
                                <span class="vs-checkbox--check">
                                    <i class="vs-icon feather icon-check" />
                                </span>
                            </span>
                            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                        </b-form-checkbox>
                    </template>

                    <template #row-details="row">
                        <b-card>
                            <b-row class="mb-2">
                                <b-col md="4" class="mb-1">
                                    <strong>Request : </strong>{{ row.item.request }}
                                </b-col>
                                <b-col md="4" class="mb-1">
                                    <strong>Type : </strong>{{ row.item.type }}
                                </b-col>
                                <b-col md="4" class="mb-1">
                                    <strong>Cost : </strong>{{ row.item.Cost }}
                                </b-col>
                                <b-col md="4" class="mb-1">
                                    <strong>Created By : </strong>{{ row.item.Created_by }}
                                </b-col>
                            </b-row>

                            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
                                Hide Details
                            </b-button>
                        </b-card>
                    </template>

                    <template #cell(avatar)="data">
                        <b-avatar :src="data.value" />
                    </template>

                    <template #cell(status)="data">
                        <b-badge :variant="status[1][data.value]">
                            {{ status[0][data.value] }}
                        </b-badge>
                    </template>
                </b-table>
                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-form>
    </div>

</b-card>
</template>

<script>
import {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BCard,
    BTable,
    BFormCheckbox,
    BBadge,
    BInputGroupAppend,
    BFormSelect,
    BInputGroup,
    BPagination
} from 'bootstrap-vue'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCard,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormDatepicker,
        BTable,
        BFormCheckbox,
        BBadge,
        BInputGroupAppend,
        BFormSelect,
        BInputGroup,
        BPagination
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            startDate: "",
            endDate: "",
            perPage: 10,
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            pageOptions: [3, 5, 10],
            nextTodoId: 2,
            showTable: false,
            fields: [{ key: 'service_name', label: 'Service Name', sortable: true }, { key: 'Rx_Port', label: 'Rx Port', sortable: true },{ key: 'status', label: 'Status' }],
            items: [{
                    service_name: "Fleek",
                    // eslint-disable-next-line global-require
                    Rx_Port: "189",
                    status:1

                },
                {
                     service_name: "Boot",
                    // eslint-disable-next-line global-require
                    Rx_Port: "767",
                    status: 2
                },
                 {
                     service_name: "leden",
                    // eslint-disable-next-line global-require
                    Rx_Port: "417",
                    status: 2
                },

            ],
              status: [{
        1: 'Pending', 2: 'Active', 3: 'Rejected'
      },
      {
        1: 'light-warning', 2: 'light-success', 3: 'light-danger'
      }],
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        this.initTrHeight()
        this.totalRows = this.items.length
    },
    created() {
        window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
        showTables() {

            if (this.endDate == "" || this.startDate == "") {
                this.$swal({
                    title: 'Error!',
                    text: ' You need to select the date',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            } else {
                this.showTable = true

            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>
